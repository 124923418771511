import React, { Component, PureComponent } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faFlask, faChartLine, faSearchPlus, faShoppingCart, faWrench, faCheckCircle, faExclamationTriangle, faUser, faChartArea, faRocket, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import PortalErrorReporting from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/HomepageErrorReporting';
import Slider from "react-slick";
import Auth from '@aws-amplify/auth'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend, Cell } from 'recharts';
import KPIComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/HomePageMainContent/KPIComponent';
import KPIGoalComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/HomePageMainContent/KPIGoalComponent';
import Feed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed';
import SourceDataFeed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed/SourceDataFeed';
import DashboardFeed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed/DashboardFeed';
import ReactPlayer from 'react-player';
import { useQuery } from 'react-query';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


function KPICard({title, api_url, api_field, suffix, hovered, onClick}) {
  const { isLoading, isError, data, error} = useQuery(
    [`homepage-${title}`, {}],
    async() => await callApiWithResult(api_url),
  )

  console.log("data", data);

  var renderValues;

  if (isLoading) {
    renderValues = {current: 'loading..', projected: null}
  } else {
    renderValues = {current: data.data[0][api_field].toLocaleString(), projected: null}
  }

  let style = hovered == title ? {borderTop: '3px solid LightBlue', boxShadow: 'none'} : {boxShadow: 'none', backgroundColor: '#f7f7f7'};
  let color = title == "Customer Growth (Monthly)" ? 'green' : 'red'

  return (
    <div className="tile is-4 card"
      onClick={onClick}
      style={style}
    >
      <div className="card-content" style={{marginTop: "-10px", marginBottom: "-25px"}}>
        <div className="columns">
          <div className="column">
            <span className="is-size-3">
              {renderValues.current}
              {suffix}
            </span>
          </div>
          <div className="column">
            {
              title == "Customer Growth (Monthly)"
              ? (<span className="has-text-success is-size-3">
                  <FontAwesomeIcon icon={faCheckCircle}/>
                </span>)
              : (<span className="has-text-danger is-size-3">
                  <FontAwesomeIcon icon={faExclamationTriangle}/>
                </span>)
            }
          </div>
        </div>
        <p className="is-size-4" style={{marginTop: "-20px"}}>
          {title}
        </p>
        <br></br>
        <KPIComponent kpiType={title} color={color}/>
      </div>
    </div>
  )
}


export default class HomePageMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpiHovered: "Carbon Emission (MT CO2e)",
      modelHovered: null,
      username: null,
      selectedSolution: ["p2b"],
      toggleDownloadModal: false,
    }
  }

  componentDidMount() {
    let { username } = this.state;
    if (username == null) this.fetchCurrentUser();
    this.getSelectedSolution()
  }

  async fetchCurrentUser() {
    try {
      let user = await Auth.currentUserInfo();
      if (window) {
        window.posthog.identify(user.attributes.email);
        window.posthog.people.set({ email: user.attributes.email });
      }
      this.setState({username: user.attributes.email})
    } catch {
      this.setState({username: ''});
    }
  }

  abbreviateNumber(number) {
    // ref https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
    var SI_SYMBOL = ["", "K", "M", "B"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  getSelectedSolution () {
    if (document.getElementsByName("solution_div")) {
      let solutionList = document.getElementsByName("solution_div")
      solutionList.forEach(solution => {
        if (this.state.selectedSolution.includes(solution.id.replace("_div", ""))) {
          solution.style.opacity = '1'
        } else {
          solution.style.opacity = '0.2'
        }
      })
    }
  }

  onClickSolutionBox (solution) {
    var { selectedSolution } = this.state;
    if ( selectedSolution.includes(solution) ) {
      // solution in the list, remove it
      selectedSolution = selectedSolution.filter((e) => e != solution)
    } else {
      // solution not in list, add it
      selectedSolution.push(solution)
    }
    this.setState({ selectedSolution });
  }

  getSolutionOpacity(solution) {
    let { selectedSolution } = this.state;
    return selectedSolution.includes(solution) ? 1 : 0.2
  }

  renderKPICard(title, values) {
    let { kpiHovered } = this.state;
    let renderValues = values || {current: 100000, projected: 200000};
    let style = kpiHovered == title ? {borderTop: '3px solid LightBlue', boxShadow: 'none'} : {boxShadow: 'none', backgroundColor: '#f7f7f7'};
    let color = title == "Customer Growth (Monthly)" ? 'green' : 'red'

    return (
      <div className="tile is-4 card"
        onClick={() => this.setState({kpiHovered: title})}
        style={style}
      >
        <div className="card-content" style={{marginTop: "-10px", marginBottom: "-25px"}}>
          <div className="columns">
            <div className="column">
              <span className="is-size-3">{title == "Customer Growth (Monthly)" ? "" : ""}{renderValues.current}{title == "Customer Growth (Monthly)" ? "%" : ""}</span>
            </div>
            <div className="column">
              {title == "Customer Growth (Monthly)" ? <span className="has-text-success is-size-3"> <FontAwesomeIcon icon={faCheckCircle}/> </span> : <span className="has-text-danger is-size-3"> <FontAwesomeIcon icon={faExclamationTriangle}/> </span>}
            </div>
          </div>
          <p className="is-size-4" style={{marginTop: "-20px"}}>
            {title}
          </p>
          <br></br>
          <KPIComponent kpiType={title} color={color}/>
        </div>
      </div>
    )
  }

  renderDataDashboardPanel() {
    return (<DashboardFeed withQuickLook={true}/>)
  }

  renderDataSourcePanel() {
    return (<SourceDataFeed />)
  }

  renderModelDeployedPanel() {
    let data = [
      {
          title: (<span><u>Carbon Projection</u> - new projection available</span>),
          // description: (<code>s3://acme-bsi-c360-upstream/bsi_shared/</code>),
          datetime: 'July 1, 2022 12:21',
          icon: faSearchPlus
      },
      {
          title: (<span><u>Carbon Projection</u> - accuracy report available</span>),
          // description: (<code>s3://acme-bsi-c360-upstream/bsi_shared/</code>),
          datetime: 'July 1, 2022 12:04',
          icon: faSearchPlus
      },
    ]

    return (
      <Feed heading="Deployed" items={data} />
  )

    // return (
    //   <div className="tile is-child panel">
    //     <div className="panel-heading">
    //       Deployed
    //     </div>
    //     {/* TODO: Make the following item generated in loop */}

    //     <div className="panel-block">
    //     <span className="panel-icon is-size-4">
    //       <FontAwesomeIcon icon={faSearchPlus} />
    //     </span>
    //     <div>
    //       <p className="is-size-5">P2B - Predictions available for February 2021</p>
    //     </div>
    //   </div>

    //   <div className="panel-block">
    //     <span className="panel-icon is-size-4">
    //       <FontAwesomeIcon icon={faChartLine} />
    //     </span>
    //     <div>
    //       <p className="is-size-5">P2B - Performance report available for January 2021</p>
    //     </div>
    //   </div>

    //   </div>
    // )
  }


  renderDataSection() {
    return (
      <div className="column is-6 is-vertical">
        <div className="card">
          <div className="card-content">
            <p className="title"> Data Processing </p>
          </div>
        </div>
        <div className="tile is-vertical">
          <div className="tile is-parent">
            {this.renderDataDashboardPanel()}
          </div>
          <div className="tile is-parent">
            {this.renderDataSourcePanel()}
          </div>
        </div>
      </div>
    )
  }


  renderModelSection() {
    return (
      <div className="column is-6 is-vertical">
        <div className="card">
          <div className="card-content">
            <p className="title"> Models </p>
          </div>
        </div>
        <div className="tile is-vertical">
          <div className="tile is-parent">
            {this.renderModelDeployedPanel()}
          </div>
          {/* <div className="tile is-parent">
            {this.renderModelStagingPanel()}
          </div> */}
        </div>
      </div>
    )
  }

  renderKPIBanner() {
    let { kpiHovered } = this.state;
    return (
      <div>
        <div className="tile is-ancestor">
          <KPICard
            title="Carbon Emission (MT CO2e)"
            api_url='lake/table/t360_carbon_overview?dataset=emission&zone=derived_restricted&fields=sum(carbon_emission)&conditions={"YEAR(timestamp)":2022}'
            api_field='carbon_emission'
            hovered={kpiHovered}
            onClick={() => this.setState({kpiHovered: "Carbon Emission (MT CO2e)"})}
          />
          {/* {this.renderKPICard("Carbon Emission (MT CO2e)", {current: "205,490", projected: 10311566})} */}
          {/* {this.renderKPICard("New Customers")} */}
          {/* {this.renderKPICard("Carbon Offset (MT CO2e)", {current: 100000, projected: 350000})} */}
          {/* {this.renderKPICard("Units Sold")} */}
          {/* {this.renderKPICard("Customer Lifetime Value", {current: 1655, projected: 2389})} */}
          {/* <button className="button is-outlined is-fullwidth">See KPI Performance Monitor</button> */}
        </div>
        { kpiHovered ? this.renderKPIBannerBody() : null}
        <br/>
        <div className="columns">
          <div className="column is-10"></div>
          <div className="column is-2">
            {/* <Link className="button" to="campaign/kpi-performance-monitor/">See Full KPI Overview</Link> */}
          </div>
        </div>
      </div>
    )
  }

  renderKPIBannerBody() {
    let { kpiHovered, toggleDownloadModal } = this.state;
    let geryBGstyle = {boxShadow: "1px 1px 5px #999999 inset", padding: '30px', backgroundColor: '#f9f9f9'};
    return (
      <div>
        <br></br>
        <div className="columns">
          <div className="column is-6 is-vertical" style={geryBGstyle}>
            <div >
              <p className="is-size-5">{kpiHovered} over Time</p>
            </div>
            <div style={{height: '300px'}}>
              {this.renderContributionChart()}
            </div>
          </div>
          {/* {this.renderContributingModel()} */}
          <div className='column is-6'>
            <div style={{margin: '0px', width: '100%'}}>
            <ReactPlayer
              url='https://www.youtube.com/watch?v=KqCe3reE4zY'
              width='100%'
            />
            </div>

            <div style={{margin: '20px'}}>
              <button className='button is-light is-large is-link' style={{width: '100%'}}
                onClick={() => this.setState({ toggleDownloadModal: ~toggleDownloadModal})}
              >
                Download - Unreal DT Client
              </button>
            </div>

            <div className={toggleDownloadModal ? 'modal is-active' : 'modal'}>
              <div className='modal-background' />
              <div className='modal-card'>
                <div className='modal-card-body'>
                  <div className='content'>
                    <h3 className='is-size-3'>How to use Unreal Client</h3>
                    <ol type="1">
                      <li>
                        Have your credentials ready at `%USERPROFILE%\.aws\credentials`.
                        More info <a href="https://docs.aws.amazon.com/sdk-for-cpp/v1/developer-guide/credentials.html#s3-1-winvs-setup-creds">here</a>, item 10 and 11.
                        You can request your Access Key ID and Secret Access Key from TRA.
                      </li>
                      <li>Download
                        <a href="https://acme-bsi-c360-artifacts-staging.s3.ap-southeast-1.amazonaws.com/unreal/udt-client.zip">
                          the Unreal Digital Twin Client
                        </a>
                        (4.1GB)
                      </li>
                      <li>Unzip the downloaded file.</li>
                      <li>
                        Within the folder `WindowsNoEditor`, double click on
                        `FactoryEnvironmentCollect.exe`
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

        <button className='modal-close is-large' aria-label='close' onClick={() => this.setState({toggleDownloadModal: false})} style={{marginTop: '80px'}}></button>
            </div>

          </div>
        </div>
      </div>
    )

  }

  renderContributionChart() {
    let { modelHovered } = this.state;
    const data = [
      {
        name: 'Jan 2021',
        baseline: 3433,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Feb 2021',
        baseline: 4130,
        p2b: 1134,
        personalizedOfferings: 899,
        salesForecast: 765,
      },
      {
        name: 'Mar 2021',
        baseline: 4284,
        p2b: 1540,
        personalizedOfferings: 989,
        salesForecast: 812,
      },
      {
        name: 'Apr 2021',
        baseline: 1896,
        p2b: 885,
        personalizedOfferings: 890,
        salesForecast: 520,
      },
      {
        name: 'May 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Jun 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'July 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Aug 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Sep 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Oct 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Nov 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Dec 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
    ];
    return (
      <div>
        <KPIGoalComponent selectedSolution={this.state.selectedSolution}/>
      </div>
    );
  }

  renderContributingModel() {
    // TODO: change list based on selected KPI
    let { modelHovered } = this.state;
    return (
      <div className="column is-6">
        <div>
          <p className="is-size-5">Solutions</p>
        </div>
        <br></br>
          <div className="columns is-multiline">
            <div className="column is-6">
              <div className="card"
                style={{backgroundColor: 'lightyellow', opacity: this.getSolutionOpacity("p2b")}}
                onClick={() => this.setState({modelHovered: 'p2b'})}
              >
                <div className="card-header" style={{height: '15px', boxShadow: 'none'}}>
                  <p className="card-header-title"></p>
                  <div className="card-header-icon" style={{paddingRight: '2px'}}>
                    <Link to="/models/p2b/"><FontAwesomeIcon icon={faExternalLinkAlt} style={{paddingBottom: '5px'}}/></Link>
                  </div>
                </div>
                <div className="card-content" style={{paddingTop: '1rem'}} onClick={() => this.onClickSolutionBox("p2b")}>
                  <div className="columns">
                    <div className="column is-10">
                      <span className="is-size-6"><FontAwesomeIcon icon={faShoppingCart} style={{margin: 'auto'}}/></span>
                      <span className="is-size-6">  Offset Project A</span>
                      <span className="is-size-6" style={{ paddingLeft: "10%"}} data-tooltip="Deployed. Time: Feb 2021"> <FontAwesomeIcon icon={faRocket}/> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="card"
                style={{backgroundColor: '#ffebed', opacity: this.getSolutionOpacity("personalize")}}
                onClick={() => this.setState({modelHovered: 'personalizedOfferings'})}
              >
                <div className="card-header" style={{height: '15px', boxShadow: 'none'}}>
                  <p className="card-header-title"></p>
                  <div className="card-header-icon" style={{paddingRight: '2px'}}>
                    <Link to="/models/personalized_offerings/"><FontAwesomeIcon icon={faExternalLinkAlt} style={{paddingBottom: '5px'}}/></Link>
                  </div>
                </div>
                <div className="card-content" style={{paddingTop: '1rem'}} onClick={() => this.onClickSolutionBox("personalize")}>
                  <span className="is-size-6"><FontAwesomeIcon icon={faUser} style={{margin: 'auto'}}/></span>
                  <span className="is-size-6">  Offset Project B</span>
                  <span className="is-size-6" style={{ paddingLeft: "10%"}} data-tooltip="Prospecting. Time: Apr 2021"> <FontAwesomeIcon icon={faFlask}/> </span>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="row">
                <div className="card"
                  style={{backgroundColor: 'LightBlue', opacity: this.getSolutionOpacity("salesforecast")}}
                  onClick={() => this.setState({modelHovered: 'salesForecast'})}
                >
                  <div className="card-header" style={{height: '15px', boxShadow: 'none'}}>
                    <p className="card-header-title"></p>
                    <div className="card-header-icon" style={{paddingRight: '2px'}}>
                      {/* <Link to="/models/p2b/"><FontAwesomeIcon icon={faExternalLinkAlt} style={{paddingBottom: '5px'}}/></Link> */}
                    </div>
                  </div>
                  <div className="card-content" style={{paddingTop: '1rem'}} onClick={() => this.onClickSolutionBox("salesforecast")}>
                    <span className="is-size-6"><FontAwesomeIcon icon={faChartArea} style={{margin: 'auto'}}/></span>
                    <span className="is-size-6">  Offset Project C</span>
                    <span className="is-size-6" style={{ paddingLeft: "10%"}} data-tooltip="Prospecting. Time: May 2021"> <FontAwesomeIcon icon={faFlask}/> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

  renderActivity() {
    return (
      <div>
        <div className="columns is-multiline">
          {this.renderDataSection()}
          {this.renderModelSection()}
        </div>
      </div>
    )
  }

  renderGreetingsHeader() {
    let { username } = this.state;
    let titleStyle = username != null ? {transition: '1s ease', opacity: 1} : {transition: '1s ease', opacity: 0}
    return (
      <div className="hero">
        <div className="hero-body">
          <div className="columns">
            <div className="column is-6" style={titleStyle}>
              <p className="title">{username > "" ? `Hi there, ${username.replace('@tra.sg', '').replace('@gmail.com', '')}!` : "Hi there!"}</p>
              <p className="subtitle">Here's what's happening in c360.ai</p>
            </div>
            <div className="column is-6" style={{overflow: 'hidden', height: '170px'}}>
             <img src="https://www.bsigroup.com/globalassets/localfiles/1400x585/amazon-river-1400x585-reversed.jpg"
               style={{marginTop: '0px'}}
             />
           </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="container">
        {this.renderGreetingsHeader()}
        {this.renderKPIBanner()}
        <br/><hr/><br/>
        {this.renderActivity()}
      </div>
    )
  }
}
